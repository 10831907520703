<template>
  <Alert status="success" v-if="success === true">
    Ti abbiamo spedito una email all'indirizzo <strong>{{email}}</strong>
  </Alert>
  <FormContainer v-else @submitForm="getChangePasswordToken">

    <Alert v-if="success === false">Si è verificato un errore non previsto, si prega di riprovare</Alert>

    <div class="row">
      <FormInputTextWidget
        class="col-12"
        label="Email"
        placeholder="example@email.com"
        v-model="email"
        :errorMsg="errorHash.email"
        type="email"
        @input="removeError('email')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-4 text-right">
        <button class="btn btn-primary font-weight-bold" type="submit">Reset</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4 text-center">
        <router-link :to="{ name: 'login' }">
          Torna al login
        </router-link>
      </div>
    </div>
  </FormContainer>

</template>

<script>

import validateMixin from '../../../libs/Form/mixins/validate';

export default {
  name: 'password-reset',
  data () {
    return {
      email: this.$route.params.email || null,
      success: null,
    };
  },
  components: {
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormContainer: () => import('../../../libs/Form/components/FormContainer'),
    Alert: () => import('../../../libs/Feedback/components/Alert'),
  },
  mixins: [validateMixin],
  methods: {
    showFinalMessage () {
      this.success = true;
    },
    notifyError () {
      this.success = false;
    },
    getChangePasswordToken () {
      if (!this.isValid()) {
        return;
      }

      this.$api.getChangePasswordToken(this.email).then(
        this.showFinalMessage,
        this.notifyError,
      ).catch(this.notifyError);
    },
    isValid () {
      this.success = null;

      if (!this.email) {
        this.addError('Inserire l\'email.', 'email');
      } else if (!this.validateEmail(this.email)) {
        this.addError('Email non valida.', 'email');
      }

      return !this.hasErrors;
    },
    validateEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};

</script>
